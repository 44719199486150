<template>
  <div class="bg_main_mobile full_h">
    <header-formulir
      v-bind:date="$date()"
      v-bind:title="'Formulir Limbah Fasyankes'"
    />
    <div class="d-flex justify-center">
      <div v-if="$isMobile()" style="margin-top: 30px; width: 85%" class="pb-5">
        <!-- Tab1 -->
        <v-card
          v-if="isiFormulir && tab == 1"
          background-color="white"
          style="padding: 40px 0"
        >
          <div class="d-flex justify-center">
            <div class="center-isi-formulir-text" v-if="dataBor">
              <div class="title-forms">
                <h6 style="font-weight: 500;" class="text-center">Persentase Bed Occupancy Rate (BOR)</h6>
              </div>
              <div class="soal-forms">
                <p class="txtTitle">Silakan input data BOR pada bulan ini</p>
                <div class="input-group">
                  <input
                    type="number"
                    v-model="dataBor.persenBOR"
                    name="bor"
                    id="bor"
                    class="form-control form-small"
                  />
                  <div class="icon-group">%</div>
                </div>
              </div>

              <div style="width: 100%;" v-if="!loading">
                <v-btn
                  white
                  v-if="!dataBor.persenBOR"
                  :disabled="!dataBor.persenBOR"
                  width="100%"
                  color="#00b4cc"
                  :class="`text-capitalize`"
                >
                  Lanjut
                  
                </v-btn>
                <v-btn
                  @click="updateBor"
                  dark
                  v-else
                  width="100%"
                  color="#00b4cc"
                  :class="`text-capitalize`"
                >
                  Lanjut
                 
                </v-btn>
              </div>
              <div style="width: 100%;" v-else>
                <v-btn
                  dark
                  width="100%"
                  color="#00b4cc"
                  :class="`text-capitalize`"
                >
                  Loading...
                </v-btn>
              </div>
            </div>
          </div>
        </v-card>
      </div>

      <div v-else style="margin-top: 50px; width: 85%" class="pb-5">
        <!-- Tab1 -->
        <v-card
          v-if="isiFormulir && tab == 1"
          background-color="white"
          style="padding: 80px 0"
        >
          <div class="d-flex justify-center">
            <div class="center-isi-formulir-text" v-if="dataBor">
              <div class="title-forms">
                <h6>Persentase Bed Occupancy Rate (BOR)</h6>
              </div>
              <div class="soal-forms">
                <p>Silakan input data BOR pada bulan ini</p>
                <div class="input-group">
                  <input
                    type="number"
                    v-model="dataBor.persenBOR"
                    name="bor"
                    id="bor"
                    class="form-control form-small"
                  />
                  <div class="icon-group">%</div>
                </div>
              </div>

              <div v-if="!loading">
                <v-btn
                  white
                  v-if="!dataBor.persenBOR"
                  :disabled="!dataBor.persenBOR"
                  width="200"
                  color="#00b4cc"
                  :class="`text-capitalize`"
                >
                  Lanjut
                  <img
                    class="ms-2 arrow-right"
                    src="../../assets/img/arrow-right.svg"
                    alt=""
                  />
                </v-btn>
                <v-btn
                  @click="updateBor"
                  dark
                  v-else
                  width="200"
                  color="#00b4cc"
                  :class="`text-capitalize`"
                >
                  Lanjut
                  <img
                    class="ms-2 arrow-right"
                    src="../../assets/img/arrow-right.svg"
                    alt=""
                  />
                </v-btn>
              </div>
              <div v-else>
                <v-btn
                  dark
                  width="200"
                  color="#00b4cc"
                  :class="`text-capitalize`"
                >
                  Loading...
                </v-btn>
              </div>
            </div>
          </div>
        </v-card>
      </div>

      
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import HeaderFormulir from "../../components/Formulir/HeaderFormulir.vue";
import { mapState } from "vuex";

export default {
  components: { HeaderFormulir },
  computed: {
    ...mapState({
      profile: (state) => state.user.profile,
    }),
  },
  data() {
    return {
      isiFormulir: true,
      tab: 1,
      loading: false,
      formulir: {
        bor: "",
      },
      dataBor: null,
    };
  },
  mounted() {
    this.getBor();
    
  },
  methods: {
    
    getProfile() {
      this.$store
        .dispatch("formulir/getIdentitas")
        .then((res) => (this.getIdentitas = res.data));
    },
    updateBor() {
      this.loading = true;
      let data = null;
      if (this.profile.usertype == "1") {
        data = {
          profilRS_id: this.dataBor.profilRS_id,
          persenBOR: this.dataBor.persenBOR,
        };
      } else {
        data = {
          profilPuskesmas_id: this.dataBor.profilPuskesmas_id,
          persenBOR: this.dataBor.persenBOR,
        };
      }
      this.$store
        .dispatch("formulir/updateBor", data)
        .then((res) => {
          this.loading = false;
          if (res.success) {
            this.$router.push(
              `/formulir/limbah-fasyankes/${this.$route.params.id}`
            );
          } else {
            Swal.fire("Gagal !", res.message, "warning");
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    getBor() {
      this.$store
        .dispatch("formulir/getBor", this.$route.params.id)
        .then((data) => {
          this.dataBor = data.data;
        });
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 600px){
  .center-isi-formulir-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 700px;
    padding-right: 30px;
    padding-left: 30px;
  }
  .txtTitle{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }
}

</style>
